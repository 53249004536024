import { updateIgnition } from "../lib/axle";

// External dependencies
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

const UnsupportedCarrier = ({
  step,
  session,
  client,
  loginInformation,
  posthog,
}) => {
  // const local = false;
  const [error, setError] = useState(null);

  useEffect(() => {
    posthog.capture("$pageview", { step });
    const exit = async () => {
      try {
        // If Ignition session has a redirectUri, redirect user with status = failed as query param
        // else, send ignition.failed webhook

        const lastEvent = {
          id: `event_${nanoid()}`,
          type: `ignition.completed`,
          data: {
            token: session.id,
            // config: session.config,
            result: loginInformation.result,
            user: session.user,
            metadata: session.metadata,
          },
          createdAt: new Date().toISOString(),
        };

        // Update Ignition session
        const params = {
          status: "completed",
          lastEvent,
        };
        await updateIgnition(session.id, params);

        session.redirectUri &&
          (window.location.href = `${session.redirectUri}?status=complete&result=${loginInformation.result}`);
      } catch (error) {
        setError(error.message);
      }
    };
    exit();
  }, []);

  return (
    <>
      {error && (
        <div
          className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-20 flex flex-col gap-y-4"
          role="status"
        >
          {" "}
          {error}{" "}
        </div>
      )}
      <div className="flex flex-col justify-center grow gap-y-8 -mt-12 p-8">
        <div className="flex justify-center">
          <div
            style={{ backgroundImage: `url("./icons/success.svg")` }}
            className={`flex-none h-32 w-full bg-contain bg-no-repeat bg-center`}
          ></div>
        </div>
        <div className="flex flex-col justify-center gap-y-4 text-center text-black">
          <h3 className="font-bold text-lg">All set!</h3>
          <p className="text-lg">
            Your verified insurance coverage will be shared with{" "}
            {client.displayName}.
          </p>
        </div>
      </div>
      {/* <ButtonPrimary
        text={"Close"}
        // onClick={() => nextStep(step)}
        width={"w-full"}
      /> */}
    </>
  );
};

export default UnsupportedCarrier;
