import Fuse from "fuse.js";
import _, { get, lowerCase } from "lodash";
import { useEffect, useState } from "react";
import { ButtonPrimary, ButtonSecondary } from "../components/Button";
import Carrier from "../components/Carrier";

export const fuzzySearchCarriers = (fuse, lowerCaseKeyword, carriers) => {
  const fuseFilteredCarriers = fuse.search(lowerCaseKeyword);
  const fuseFilteredCarrierItems = fuseFilteredCarriers.map((carrier) => {
    return get(carrier, "item");
  });

  // Match substring of carrier name and domain
  const stringFilteredCarriers = carriers.filter((carrier) => {
    const carrierNameContainsKeyword = lowerCase(get(carrier, "name")).includes(
      lowerCaseKeyword
    );

    const carrierDomainContainsKeyword = lowerCase(
      get(carrier, "domain")
    ).includes(lowerCaseKeyword);
    return carrierNameContainsKeyword || carrierDomainContainsKeyword;
  });
  const results = [...stringFilteredCarriers, ...fuseFilteredCarrierItems];
  // Remove duplicates
  const uniqueResults = [...new Set(results)];
  return uniqueResults;
};

const CreditCardOptions = ({ nextStep }) => {
  return (
    <div className="flex flex-col gap-y-6 mt-auto">
      <hr className="-mt-8 -mx-8 border-gray-10 [box-shadow:0px_-1px_2px_rgba(0,_0,_0,_0.06)]" />
      <ButtonSecondary
        onClick={() => nextStep("credit-cards")}
        text={"Use my credit card protection instead"}
      />
    </div>
  );
};

const Carriers = ({
  step,
  nextStep,
  setCarrier,
  session,
  posthog,
  setCarriers,
}) => {
  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);
  const carriers = get(session, "carriers");

  const manualEnabled = get(session, "config.manual.enabled");
  const manualCreditCardEnabled = get(session, "config.manual.creditCard");

  const [carrierName, setCarrierName] = useState("");
  const [filteredCarriers, setFilteredCarriers] = useState(carriers);
  setCarriers(carriers);

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const lowerCaseKeyword = lowerCase(keyword);
      // Use Fuse for fuzzy search
      const fuse = new Fuse(carriers, {
        keys: ["name", "domain"],
        threshold: 0.4,
      });
      const uniqueResults = fuzzySearchCarriers(
        fuse,
        lowerCaseKeyword,
        carriers
      );
      setFilteredCarriers(uniqueResults);
    } else {
      setFilteredCarriers(carriers);
      // If the text field is empty, show all users
    }

    setCarrierName(keyword);
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <h3 className="text-xl text-black font-bold">
          Find your insurance carrier
        </h3>
        <input
          placeholder="Search"
          className="border border-solid border-black p-3 text-base rounded-sm text-black"
          type="search"
          value={carrierName}
          onChange={filter}
        />
      </div>

      {filteredCarriers && filteredCarriers.length > 0 ? (
        <>
          <div className="flex flex-col overflow-y-auto -mx-8 pb-8">
            <div className="overflow-visible filteredCarriers">
              {_(filteredCarriers)
                .chain()
                .map((carrier) => (
                  <Carrier
                    key={carrier.id}
                    config={get(session, "config")}
                    carrier={carrier}
                    nextStep={nextStep}
                    step={step}
                    setCarrier={setCarrier}
                  />
                ))
                .value()}
              <button
                className="flex flex-col gap-y-4 px-8 pt-4 hover:bg-gray-100 focus:bg-gray-100 cursor-pointer w-full text-justify"
                onClick={() => {
                  posthog.capture("my-carrier-is-not-listed", {
                    carrier: carrierName,
                  });
                  manualEnabled
                    ? nextStep("manual-account")
                    : nextStep("exit-confirmation");
                  // Clear carrier if previously selected
                  setCarrier({ carrier: {} });
                }}
              >
                <div className="flex gap-x-4">
                  <div
                    style={{
                      backgroundImage: `url("https://axle-labs-assets.s3.amazonaws.com/carrierLogos/generic.svg")`,
                    }}
                    className="inline-block rounded-full h-12 w-12 bg-blue bg-cover bg-center"
                  ></div>
                  <div>
                    <h4 className="text-base font-medium text-black">Other</h4>
                    <p className="text-base text-black">
                      My carrier is not listed
                    </p>
                  </div>
                </div>
                <hr className="border-gray-100 w-full" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col">
            <h1 className="text-md text-black font-bold">No results found.</h1>
            <p>Retry, or continue if your carrier isn't listed.</p>
          </div>

          <div className="flex flex-col gap-y-6 mt-auto">
            <hr className="-mx-8 border-gray-100" />
            <ButtonPrimary
              text={"Continue"}
              onClick={() => {
                posthog.capture("my-carrier-is-not-listed", {
                  carrier: carrierName,
                });
                manualEnabled
                  ? nextStep("manual-account")
                  : nextStep("exit-confirmation");
                // Clear carrier if previously selected
                setCarrier({ carrier: {} });
              }}
              width={"w-full"}
            />
          </div>
        </>
      )}
      {/* <div className="absolute block bottom-0 left-0 w-full h-14 bg-gradient-to-t from-white"></div> */}
      {manualEnabled && manualCreditCardEnabled && (
        <CreditCardOptions nextStep={nextStep} />
      )}
    </>
  );
};

export default Carriers;
