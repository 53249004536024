import _, { get } from "lodash";
import { useEffect, useRef, useState } from "react";

const CarrierLoader = ({ loginInformation, loadingHeader, loadingSteps }) => {
  const [loadingCounter, setLoadingCounter] = useState(0);
  const intervalId = useRef(null);

  const loadingImage = get(
    loginInformation,
    "carrier.image",
    "https://axle-labs-assets.s3.amazonaws.com/carrierLogos/generic.svg"
  );

  const carrierName = get(loginInformation, "carrier.name", null);

  // Loop through loading steps and stop at end of list
  useEffect(() => {
    if (loadingCounter + 1 < loadingSteps.length) {
      intervalId.current = setInterval(() => {
        setLoadingCounter((loadingCounter) => loadingCounter + 1);
      }, 2000);
    }
    return () => clearInterval(intervalId.current);
  }, [loadingCounter]);

  return (
    <div className="flex flex-col gap-y-16 justify-between h-full grow">
      <div className="flex flex-col gap-y-2">
        <h3 className="text-xl text-black font-bold">{loadingHeader}</h3>
        <p className="text-lg text-gray-500 ellipses">
          {loadingSteps[loadingCounter]}
        </p>
      </div>
      <div className="grid items-center justify-items-center">
        <div className="animate-ease h-48 w-48 border-4 rounded-full border-t-gray-200 border-l-white border-r-white border-b-white row-start-1 col-start-1"></div>
        <div className="row-start-1 col-start-1">
          <div className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center"></div>
          <div
            style={{
              backgroundImage: `url("${loadingImage}")`,
              // backgroundColor: loginInformation.carrier.color,
            }}
            className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center border border-solid border-white box-border -ml-2"
          ></div>
        </div>
      </div>
      <div className="flex flex-col">
        <p className="text-black text-base font-bold">Note</p>
        <p className="text-gray-500 text-base">
          {carrierName
            ? `You may be notified by ${carrierName} of a new
            login attempt.`
            : "We are currently processing your document."}
        </p>
      </div>
    </div>
  );
};

export default CarrierLoader;
