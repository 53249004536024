import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useState, useEffect } from "react";
import _, { get } from "lodash";
import { RadioGroup } from "@headlessui/react";
import { ButtonPrimary } from "../components/Button";
import { linkDocuments, linkPolicy } from "../lib/axle";
import CarrierLoader from "./CarrierLoader";
import Policy from "../components/Policy";

const Confirm = ({
  nextStep,
  step,
  accountInfo,
  loginInformation,
  session,
  setPolicyInfo,
  carriers,
  setLinkError,
  posthog,
  setPartnerCarrierConfig,
  setShowNav,
}) => {
  useEffect(() => {
    posthog.capture("$pageview", {
      step,
      carrier: get(loginInformation, "carrier.id"),
    });
  }, [posthog]);

  const ignitionToken = session.id;
  const policies = get(accountInfo, "policies", []);

  const [error, setError] = useState(null);
  const [policyIndex, setPolicyIndex] = useState(0);
  const [policy, setPolicy] = useState(get(policies, policyIndex));

  // Configure carrier loader
  const { promiseInProgress } = usePromiseTracker({ area: "carrier-loader" });
  const loadingSteps = [
    "Establishing a secure connection",
    "Contacting your carrier",
    "Retrieving policy details",
  ];

  const onClick = async (index) => {
    setPolicyIndex(index);
  };

  const fetchPolicyAndDocuments = async (policyType, policyNumber) => {
    // Hide nav buttons while CarrierLoader is rendered for accessibility
    setShowNav(false);

    try {
      // Link policy to account
      const policy = await linkPolicy(ignitionToken, policyType, policyNumber);
      setPolicyInfo({ id: policy.id });

      // Attempt to link documents to policy, but do not throw on error
      try {
        await linkDocuments(ignitionToken, policyType, policyNumber, policy.id);
      } catch {
        // Can use this in the future to handle getDocuments errors
      }

      // If require backup docs, go to manual_policy_docs, else go to success
      get(session, "config.documentation.enabled")
        ? nextStep("manual-policy-document")
        : nextStep("success");
    } catch (error) {
      switch (error.code) {
        case 400:
          if (error.message === "Policy number is invalid or does not exist.") {
            setLinkError("retrieve policy details");
            nextStep("carrier-error");
            return;
          } else if (
            error.message ===
            "Sorry, your insurance account or policy has not been set up for online access."
          ) {
            nextStep("account-pending");
            return;
          }
        case 401:
        case 403:
          setLinkError(error.message);
          nextStep("session-expired");
          return;
        default:
          setLinkError("retrieve policy details");
          nextStep("carrier-error");
          return;
      }
    }
  };

  const onSubmit = async () => {
    posthog.capture("confirm-policy-selection", {
      step,
      carrier: get(loginInformation, "carrier.id"),
    });

    const { policyType, policyNumber, policyPartner } = get(
      policies,
      policyIndex
    );

    // If policy has a partner, redirect to partner login page
    if (policyPartner) {
      let policyPartnerCarrierConfig = carriers.find(
        (carrier) => carrier.id === policyPartner
      );
      setPartnerCarrierConfig({ carrier: policyPartnerCarrierConfig });
      nextStep("redirect-login");
      return;
    }

    await trackPromise(
      fetchPolicyAndDocuments(policyType, policyNumber),
      "carrier-loader"
    );

    // Show nav buttons after CarrierLoader is unrendered
    setShowNav(true);
  };

  return (
    <>
      {promiseInProgress ? (
        <CarrierLoader
          loginInformation={loginInformation}
          loadingHeader="Linking policy"
          loadingSteps={loadingSteps}
        />
      ) : (
        <>
          <div className="flex">
            <div
              className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center"
              aria-label="Axle"
            ></div>
            <div
              style={{
                backgroundImage: `url("${loginInformation.carrier.image}")`,
                // backgroundColor: loginInformation.carrier.color,
              }}
              className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center border border-solid border-white box-border transform -translate-x-2"
              aria-label={loginInformation.carrier.name}
            ></div>
          </div>
          <div className="flex flex-col gap-y-2">
            <h3 className="text-xl text-black font-bold">Choose policy</h3>
            <p className="text-lg text-black">
              Axle will only share information from the selected policy.
            </p>
          </div>
          <ul className="flex flex-col gap-y-4 overflow-y-auto -mb-8 pb-8">
            {error && (
              <div
                className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
                role="status"
              >
                {" "}
                {error}{" "}
              </div>
            )}
            {_.size(policies) > 0 ? (
              <RadioGroup
                value={policy}
                onChange={setPolicy}
                className="flex flex-col gap-y-4"
              >
                {policies.map((policy, index) => (
                  <Policy
                    key={get(policy, "policyNumber")}
                    policy={policy}
                    onClick={() => onClick(index)}
                  />
                ))}
              </RadioGroup>
            ) : (
              <h1>No policies were found on your account!</h1>
            )}
          </ul>
          {_.size(policies) > 0 && (
            <div className="flex flex-col gap-y-4 mt-auto">
              <hr className="-mx-8 border-gray-100" />
              <ButtonPrimary
                text={"Continue"}
                width={"w-full"}
                type={"submit"}
                onClick={() => onSubmit()}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Confirm;
