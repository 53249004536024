import capitalize from "lodash/capitalize";

const Radio = ({ title, subtext, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex flex-row text-black hover:text-white border border-solid border-black bg-white hover:bg-black focus:bg-black focus:text-white rounded-sm p-4 filter shadow transition-colors cursor-pointer justify-between"
    >
      <h3 className="text-base font-bold">{capitalize(title)}</h3>
      {subtext && <p className="text-base">{subtext.toLowerCase()}</p>}
    </button>
  );
};

export default Radio;
