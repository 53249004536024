import _, { lowerCase } from "lodash";
import { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { ButtonPrimary } from "../components/Button";
import { fuzzySearchCarriers } from "./Carriers";
import { Row } from "../components/Carrier";
import { DEFAULT_CREDIT_CARD_IMAGE_GENERIC } from "../constants";

const CREDIT_CARDS = [
  {
    id: "chase-sapphire-reserve",
    name: "Chase Sapphire Reserve",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl: "https://creditcards.chase.com/",
    registerUrl:
      "https://creditcards.chase.com/rewards-credit-cards/sapphire/reserve",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/chase.svg",
    claimsUrl: "https://www.eclaimsline.com/",
  },
  {
    id: "chase-sapphire-preferred",
    name: "Chase Sapphire Preferred",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl: "https://creditcards.chase.com/",
    registerUrl:
      "https://creditcards.chase.com/rewards-credit-cards/sapphire/preferred/test",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/chase.svg",
    claimsUrl: "https://www.eclaimsline.com/",
  },
  {
    id: "capital-one-venture-x",
    name: "Capital One Venture X",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl: "https://verified.capitalone.com/auth/signin",
    registerUrl: "https://www.capitalone.com/credit-cards/venture-x/",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/capital-one.svg",
    claimsUrl: "https://www.eclaimsline.com/",
  },
  {
    id: "united-explorer",
    name: "United Explorer",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl: "https://www2.theexplorercard.com/rewards-cards/explorer-card",
    registerUrl:
      "https://www2.theexplorercard.com/application-processing?SPID=HBTR&CELL=PH6&REFERID=TEC",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/chase.svg",
    claimsUrl:
      "https://www.chase.com/personal/credit-cards/united/united-visa-infinite-card/purchase-protection#:~:text=For%20assistance%20with%20all%20United,%2D804%2D673%2D1691.",
  },
  {
    id: "united-quest",
    name: "United Quest",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl: "https://www2.theexplorercard.com/rewards-cards/quest-card",
    registerUrl:
      "https://www2.theexplorercard.com/application-processing?SPID=HBJK&CELL=H8X&REFERID=TEC",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/chase.svg",
    claimsUrl:
      "https://www.chase.com/personal/credit-cards/united/united-visa-infinite-card/purchase-protection#:~:text=For%20assistance%20with%20all%20United,%2D804%2D673%2D1691.",
  },
  {
    id: "united-club-infinite",
    name: "United Club Infinite",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl: "https://www2.theexplorercard.com/rewards-cards/club-card",
    registerUrl:
      "https://www2.theexplorercard.com/application-processing?SPID=HBTR&CELL=PH6&REFERID=TEC",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/chase.svg",
    claimsUrl:
      "https://www.chase.com/personal/credit-cards/united/united-visa-infinite-card/purchase-protection#:~:text=For%20assistance%20with%20all%20United,%2D804%2D673%2D1691.",
  },
  {
    id: "united-business",
    name: "United Business",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl:
      "https://www2.theexplorercard.com/business-rewards-cards/business-card",
    registerUrl:
      "https://www2.theexplorercard.com/application-processing?SPID=HBTR&CELL=PH6&REFERID=TEC",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/chase.svg",
    claimsUrl:
      "https://www.chase.com/personal/credit-cards/united/united-visa-infinite-card/purchase-protection#:~:text=For%20assistance%20with%20all%20United,%2D804%2D673%2D1691.",
  },
  {
    id: "american-express",
    name: "American Express Premium Rental Car Protection",
    domain: "Credit card add on",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl:
      "https://www.americanexpress.com/en-us/account/login?inav=iNavLnkLog",
    registerUrl:
      "https://feeservices.americanexpress.com/premium/car-rental-insurance-coverage/home.do",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/american-express.svg",
    claimsUrl:
      "https://www.americanexpress.com/en-us/benefits/protections/dashboard/login",
  },
];

const INACTIVE_CREDIT_CARDS = [
  {
    id: "ink-business-preferred",
    name: "Ink Business Preferred",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl: "https://www.chase.com/business",
    registerUrl:
      "https://creditcards.chase.com/business-credit-cards/ink/business-preferred",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/chase.svg",
  },
  {
    id: "ink-business-cash-credit",
    name: "Ink Business Cash Credit",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl: "https://www.chase.com/business",
    registerUrl:
      "https://creditcards.chase.com/business-credit-cards/ink/cash?iCELL=6C1Y",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/chase.svg",
  },
  {
    id: "ink-business-unlimited-credit",
    name: "Ink Business Unlimited Credit",
    domain: "Credit card",
    type: "manual",
    status: "active",
    hasImage: true,
    recoveryUrl: "https://www.chase.com/business",
    registerUrl:
      "https://creditcards.chase.com/business-credit-cards/ink/unlimited?iCELL=6C1Y",
    image:
      "https://axle-labs-assets.s3.amazonaws.com/creditCardLogos/chase.svg",
  },
];

const CreditCards = ({
  step,
  setLoginInformation,
  nextStep,
  session,
  posthog,
}) => {
  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);
  const creditCards = CREDIT_CARDS;

  const [creditCardName, setCreditCardName] = useState("");
  const [filteredCreditCards, setFilteredCreditCards] = useState(creditCards);

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const lowerCaseKeyword = lowerCase(keyword);
      // Use Fuse for fuzzy search
      const fuse = new Fuse(creditCards, {
        keys: ["name", "domain"],
        threshold: 0.4,
      });
      const uniqueResults = fuzzySearchCarriers(
        fuse,
        lowerCaseKeyword,
        creditCards
      );
      setFilteredCreditCards(uniqueResults);
    } else {
      setFilteredCreditCards(creditCards);
      // If the text field is empty, show all users
    }

    setCreditCardName(keyword);
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <h3 className="text-xl text-black font-bold">
          Choose your credit card
        </h3>
        <input
          placeholder="Search"
          className="border border-solid border-black p-3 text-base rounded-sm text-black"
          type="search"
          value={creditCardName}
          onChange={filter}
        />
      </div>

      {filteredCreditCards && filteredCreditCards.length > 0 ? (
        <>
          <div className="flex flex-col overflow-y-auto -mx-8 -mb-8 pb-8">
            <div className="overflow-visible filteredCarriers">
              {_(filteredCreditCards)
                .chain()
                .map((creditCard, index) => (
                  <button
                    key={index}
                    className="flex flex-col gap-y-4 px-8 pt-4 text-justify hover:bg-gray-100 focus:bg-gray-100 cursor-pointer w-full"
                    onClick={() => {
                      setLoginInformation(creditCard);
                      nextStep("manual-credit-card");
                    }}
                  >
                    <Row
                      backgroundImageUrl={creditCard.image}
                      rowHeader={creditCard.name}
                      rowSubHeader={creditCard.domain}
                    />
                    <hr className=" border-gray-100 w-full" />
                  </button>
                ))
                .value()}
              <button
                className="flex flex-col gap-y-4 px-8 pt-4 text-justify hover:bg-gray-100 focus:bg-gray-100 cursor-pointer w-full"
                onClick={() => {
                  posthog.capture("my-credit-card-is-not-listed", {
                    carrier: creditCardName,
                  });
                  setLoginInformation({});
                  nextStep("manual-credit-card");
                }}
              >
                <Row
                  backgroundImageUrl={DEFAULT_CREDIT_CARD_IMAGE_GENERIC}
                  rowHeader="Other"
                  rowSubHeader="My credit card is not listed"
                />

                <hr className="border-gray-100 w-full" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col">
            <h1 className="text-md text-black font-bold">No results found.</h1>
            <p>Retry, or continue if your credit card isn't listed.</p>
          </div>

          <div className="flex flex-col gap-y-6 mt-auto">
            <hr className="-mx-8 border-gray-100" />
            {/* <p className="text-center text-gray-500 text-xs">
          View our Glossary for more information
        </p> */}
            <ButtonPrimary
              text={"Continue"}
              onClick={() => {
                posthog.capture("my-credit-card-is-not-listed", {
                  carrier: creditCardName,
                });
                setLoginInformation({});
                nextStep("manual-credit-card");
              }}
              width={"w-full"}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CreditCards;
