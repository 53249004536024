import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useState, useEffect } from "react";
import _, { get } from "lodash";

import { enterMfa, getAccount, login } from "../lib/axle";
import { ButtonPrimary } from "../components/Button";
import CarrierLoader from "./CarrierLoader";

const EnterMfa = ({
  step,
  nextStep,
  setAccountInfo,
  loginInformation,
  session,
  setLinkError,
  posthog,
  setShowNav,
}) => {
  // Track pageview
  useEffect(() => {
    posthog.capture("$pageview", {
      step,
      carrier: get(loginInformation, "carrier.id"),
    });
  }, [posthog]);

  const carrier = get(loginInformation, "carrier.id");
  const ignitionToken = session.id;
  const type = loginInformation.mfa.type;

  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(null);

  // Configure carrier loader
  const { promiseInProgress } = usePromiseTracker({ area: "carrier-loader" });
  const loadingSteps = [
    "Establishing a secure connection",
    "Contacting your carrier",
    "Sending verification code",
    "Retrieving account information",
  ];

  // Form logic
  const enterMfaGetAccount = async () => {
    // Hide nav buttons while CarrierLoader is rendered for accessibility
    setShowNav(false);

    try {
      if (verificationCode.length < 3) {
        // Backend will throw error if it recieves an MFA code that is too short.
        setError("Verification code must be 3+ characters.");
        return;
      } else {
        // Submit MFA code
        await enterMfa(ignitionToken, carrier, type, verificationCode);
      }
    } catch (error) {
      switch (error.code) {
        // Incorrect mfa code
        case 400:
          setError(error.message);
          return;
        // If session expired, send customer to session-expired
        case 401:
        case 403:
          setLinkError(error.message);
          nextStep("session-expired");
          return;
        default:
          setLinkError("enter MFA code");
          nextStep("carrier-error");
          return;
      }
    }

    try {
      const account = await getAccount(ignitionToken);

      // If no compatible policies on account send to "no-policies"
      if (_(account.policies).size() === 0) {
        nextStep("no-policies");
        return;
      }

      // Save account info to state
      setAccountInfo(account);

      // Go to account selection step
      nextStep("confirm");
      return;
    } catch (error) {
      switch (error.code) {
        case 400:
          if (
            error.message ===
            "Sorry, your insurance account or policy has not been set up for online access."
          ) {
            nextStep("account-pending");
            return;
          }
          return;
        // If session expired, send customer to session-expired. Change 7/10/23 to back to carrier-error
        case 401:
        case 403:
          setLinkError(error.message);
          nextStep("carrier-error");
          return;
        case 503:
          setLinkError(error.message);
          nextStep("carrier-maintenance");
          return;
        default:
          setLinkError("retrieve account details");
          nextStep("carrier-error");
          return;
      }
    }
  };
  const onSubmit = async (e) => {
    // Prevent default form behavior
    e.preventDefault();

    // Validate required input fields
    if (!verificationCode) {
      setError("Please enter your verification code!");
      return;
    }

    // Enter MFA code and get account details
    await trackPromise(enterMfaGetAccount(), "carrier-loader");

    // Show nav buttons after CarrierLoader is unrendered
    setShowNav(true);
  };

  return (
    <>
      {promiseInProgress ? (
        <CarrierLoader
          loginInformation={loginInformation}
          loadingHeader="Verifying identity"
          loadingSteps={loadingSteps}
        />
      ) : (
        <>
          <div className="flex">
            <div
              className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
              aria-label="Axle"
            ></div>
            <div
              style={{
                backgroundImage: `url("${loginInformation.carrier.image}")`,
                // backgroundColor: loginInformation.carrier.color,
              }}
              className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
              aria-label={loginInformation.carrier.name}
            ></div>
          </div>
          <div className="flex flex-col gap-y-2">
            <h3 className="text-xl text-black font-bold">
              Verify your identity
            </h3>
            <p className="text-base text-black">
              Enter the code you received from{" "}
              <b>{loginInformation.carrier.name}</b>.
            </p>
          </div>
          <form className="flex flex-col gap-y-8" onSubmit={onSubmit}>
            <div className="flex flex-col gap-y-6">
              {error && (
                <div
                  className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
                  role="status"
                >
                  {" "}
                  {error}{" "}
                </div>
              )}
              <input
                placeholder="Verification code"
                value={verificationCode}
                className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                type="text"
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </div>
            <ButtonPrimary
              text={"Submit"}
              //onClick={() => nextStep(step)}
              width={"w-full"}
              type={"submit"}
            />
          </form>
        </>
      )}
    </>
  );
};

export default EnterMfa;
