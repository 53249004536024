import { ButtonPrimary } from "../components/Button";
import Permission from "../components/Permission";
import { basePermissions, genericClientLogoUrl } from "../lib/constants";
import { useEffect } from "react";
import _, { get } from "lodash";

const Permissions = ({ step, nextStep, client, posthog, session }) => {
  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  let perms;
  const policyTypes = _(session).get("config.scopes.policyTypes", []);
  const permissions = _(session).get("config.scopes.permissions", undefined);
  const vehiclePolicyTypes = ["auto", "motorcycle", "trailer", "boat", "rv"];
  const dwellingPolicyTypes = ["home", "condo", "tenant", "landlord"];

  // If client has specified permissions, then join their permissions with base permissions, prioritizing theirs first
  if (permissions) {
    perms = _.unionBy(permissions, basePermissions, "value");
    // Sort permissions in ascending order
    perms = _.sortBy(perms, ["order"], ["asc"]);
  } else if (
    policyTypes.some((i) => vehiclePolicyTypes.includes(i)) &&
    policyTypes.some((i) => dwellingPolicyTypes.includes(i))
  ) {
    perms = [
      ...basePermissions,
      {
        order: 7,
        name: "Properties",
        icon: "key",
        value: "properties",
      },
    ];
  } else if (policyTypes.some((i) => vehiclePolicyTypes.includes(i))) {
    perms = [
      ...basePermissions,
      {
        order: 7,
        name: "Vehicles",
        icon: "wheel",
        value: "properties",
      },
    ];
  } else if (policyTypes.some((i) => dwellingPolicyTypes.includes(i))) {
    perms = [
      ...basePermissions,
      {
        order: 7,
        name: "Dwellings",
        icon: "house",
        value: "properties",
      },
    ];
  } else {
    perms = [
      ...basePermissions,
      {
        order: 7,
        name: "Properties",
        icon: "key",
        value: "properties",
      },
    ];
  }

  // If monitoring is enabeld, add to Permissions
  if (get(session, "config.scopes.monitoring")) {
    perms = [
      ...perms,
      {
        order: 11,
        name: "Policy Updates",
        icon: "monitoring",
        value: "monitoring",
      },
    ];
  }

  const logoClass =
    "inline-block rounded-full h-12 w-12 bg-white bg-no-repeat bg-cover bg-center border border-solid border-white box-border transform -translate-x-1.5	";

  const clientLogoUrl = client.logoUrl ?? genericClientLogoUrl;

  return (
    <>
      <div className="flex">
        <div
          className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center"
          aria-label="Axle"
        ></div>
        <div
          className={logoClass}
          style={{ backgroundImage: `url('${clientLogoUrl}')` }}
          aria-label={client.displayName}
        ></div>
        {/* <div className="flex items-center justify-center rounded-full h-12 w-12 bg-emerald-500 text-white font-medium text-2xl border border-solid border-white box-border transform -translate-x-1.5	">
          S
        </div> */}
      </div>
      <h3 className="text-xl text-black">
        <b>{client.displayName}</b> will receive the following information about
        your policy:
      </h3>
      <ul
        className="flex flex-col gap-y-4 overflow-y-auto -mb-8 pb-8"
        aria-labelledby="List of permissions"
      >
        {_(perms)
          .chain()
          .orderBy(["order"], ["asc"])
          .map((perm) => (
            <Permission key={perm.order} name={perm.name} icon={perm.icon} />
          ))
          .value()}
      </ul>
      <div className="flex flex-col gap-y-6 mt-auto">
        <hr className="-mx-8 border-gray-100" />
        <ButtonPrimary
          text={"Continue"}
          onClick={() => nextStep("carriers")}
          width={"w-full"}
        />
      </div>
    </>
  );
};

export default Permissions;
