import Icon from "./Icon";

const Permission = ({ name, icon }) => {
  return (
    <li className="flex gap-x-3" tabIndex={`0`}>
      <Icon name={icon} ariaLabel={name} />
      <div>
        <h4 className="text-base text-black">{name}</h4>
      </div>
    </li>
  );
};

export default Permission;
