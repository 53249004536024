import { trackPromise } from "react-promise-tracker";
import { ButtonPrimary } from "../components/Button";
import { DEFAULT_CREDIT_CARD_IMAGE_GENERIC } from "../constants";
import { stubAccount, stubPolicy, updateIgnition } from "../lib/axle";

// External dependencies
import _, { get, size } from "lodash";
import { useEffect, useState } from "react";
import { AnchorSecondary } from "../components/Anchor";

const ManualCreditCard = ({
  step,
  nextStep,
  setAccountInfo,
  setPolicyInfo,
  loginInformation,
  setLoginInformation,
  session,
  posthog,
}) => {
  useEffect(() => {
    const creditCardId = get(loginInformation, "id");

    const updateIgnitionManualCreditCard = async () => {
      await updateIgnition(session.id, {
        carrier: creditCardId,
      });
    };
    updateIgnitionManualCreditCard();
    posthog.capture("$pageview", { step });
  }, [posthog]);

  const ignitionToken = session.id;

  const [creditCardName, setCreditCardName] = useState("");
  const [fields, setFields] = useState({});
  const [error, setError] = useState(null);

  const hasCreditCard = get(loginInformation, "id") ? true : false;
  const claimsUrl = get(loginInformation, "claimsUrl", false);
  const baseFields = [
    {
      order: 1,
      enabled: true,
      required: true,
      value: "firstName",
      type: "text",
      placeholder: "Card holder first name",
    },
    {
      order: 2,
      enabled: true,
      required: true,
      value: "lastName",
      type: "text",
      placeholder: "Card holder last name",
    },
  ];
  const combinedFields = _.chain()
    .unionBy(
      get(session, "config.manual-credit-card.additional-fields", []),
      baseFields,
      "value"
    )
    .sortBy(["order"], ["asc"])
    .value();

  const createStubAxleEntities = async () => {
    try {
      const carrier = hasCreditCard
        ? get(loginInformation, "id")
        : creditCardName;

      const constructedAccount = {
        firstName: get(fields, "firstName"),
        lastName: get(fields, "firstName"),
        carrier,
      };

      // Create Account
      const account = await stubAccount(ignitionToken, constructedAccount);

      const accountId = get(account, "id");

      // Create Policy
      const policy = await stubPolicy(ignitionToken, {
        account: accountId,
        carrier,
        ...fields,
      });

      // Set result to manual
      setLoginInformation({
        ...loginInformation,
        result: "manual",
      });

      // Save account information
      setAccountInfo({
        id: accountId,
        ...constructedAccount,
      });

      // Save policy information
      setPolicyInfo({ id: get(policy, "id") });

      return "success";
    } catch (error) {
      return "failed";
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!hasCreditCard && !creditCardName) {
      setError("Please add a credit card name.");
      return;
    }

    // Input validation on credit card name
    // Warn user's if they've accidentlaly entered a credit card number
    // Check if string entered contians multiple numbers
    if (
      creditCardName &&
      creditCardName.match(/\d/g) &&
      size(creditCardName.match(/\d/g)) > 1
    ) {
      setError(
        "Please enter a valid credit card name, not a credit card number."
      );
      return;
    }

    // Initialize error tracker
    let errorMessage = "";
    let hasError = false;

    // Check if any required additional fields are missing
    _(combinedFields)
      .chain()
      .filter((item) => item.required)
      .forEach((item) => {
        if (!fields[item.value]) {
          errorMessage = `You must provide your ${item.placeholder}.`;
          hasError = true;
        }
      })
      .value();

    // If one required field is missing a value, then setError
    if (hasError) {
      setError(errorMessage);
      return;
    }

    nextStep(await trackPromise(createStubAxleEntities()));
  };

  return (
    <>
      <div className="flex">
        <div
          className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
          aria-label="Axle"
        ></div>
        <div
          style={{
            backgroundImage: `url("${
              hasCreditCard
                ? get(loginInformation, "image")
                : DEFAULT_CREDIT_CARD_IMAGE_GENERIC
            }")`,
          }}
          className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
          aria-label={get(loginInformation, "name", "Unknown credit card")}
        ></div>
      </div>
      <div className="flex flex-col gap-y-2">
        <h3 className="text-xl text-black font-bold">
          Enter your card holder information
        </h3>
        <p className="text-base text-black">
          <>Axle will only share the information you provide.</>
        </p>
      </div>
      <form className="flex flex-col h-full gap-y-8" onSubmit={onSubmit}>
        <div className="flex flex-col gap-y-4 mt-auto">
          {error && (
            <div
              className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
              role="status"
            >
              {" "}
              {error}{" "}
            </div>
          )}
          {!hasCreditCard && (
            <input
              placeholder="Credit card name (e.g. Chase Sapphire Reserve)"
              value={creditCardName}
              className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
              type="text"
              onChange={(e) => setCreditCardName(e.target.value)}
            />
          )}

          {_(combinedFields)
            .chain()
            .filter((item) => item.enabled)
            .map((item) => (
              <input
                placeholder={item.placeholder}
                value={fields[item.value]}
                className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                type={item.type}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    [item.value]: e.target.value,
                  })
                }
              />
            ))
            .value()}
        </div>

        <div className="flex flex-col gap-y-4 mt-auto">
          <ButtonPrimary text={"Continue"} width={"w-full"} type={"submit"} />
          {claimsUrl && get(session, "config.manual-credit-card.claims") && (
            <AnchorSecondary
              url={claimsUrl}
              onClick={() => {
                // Capture posthog event for filing a credit card claim
                posthog.capture("file-credit-card-claim", { creditCardName });
              }}
              text={"Need help filing a claim?"}
            />
          )}
        </div>
      </form>
    </>
  );
};

export default ManualCreditCard;
