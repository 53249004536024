import { ButtonPrimary } from "../components/Button";
import { stubAccount, updateIgnition } from "../lib/axle";

// External dependencies
import { get, size } from "lodash";
import { useEffect, useState } from "react";

const ManualAccount = ({
  step,
  nextStep,
  setAccountInfo,
  loginInformation,
  setLoginInformation,
  session,
  history,
  setHistory,
  posthog,
}) => {
  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  const ignitionToken = session.id;

  const [carrierName, setCarrierName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (get(loginInformation, "carrier.id")) {
      async function updateIgnitionWithCarrier() {
        const params = {
          carrier: get(loginInformation, "carrier.id"),
        };
        await updateIgnition(session.id, params);
      }
      updateIgnitionWithCarrier();
    }
    const isEnhancedEnabled = get(session, "config.manual.enhanced");

    if (isEnhancedEnabled) {
      nextStep("enhanced-manual-policy-document");

      setHistory(history);

      return;
    }
  }, []);

  const hasCarrier = get(loginInformation, "carrier.id") ? true : false;

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!hasCarrier && !carrierName) {
      setError("Please add a carrier!");
      return;
    }

    if (!firstName) {
      setError("Please enter the policyholder's first name!");
      return;
    }

    if (!lastName) {
      setError("Please enter the policyholder's last name!");
      return;
    }

    try {
      // Save account information
      const account = await stubAccount(ignitionToken, {
        firstName: firstName,
        lastName: lastName,
        carrier: hasCarrier ? get(loginInformation, "carrier.id") : carrierName,
      });

      // Save policy information
      setAccountInfo({
        id: account.id,
        firstName,
        lastName,
        carrier: hasCarrier ? get(loginInformation, "carrier.id") : carrierName,
      });

      // Set result to manual if not basic
      loginInformation.result === "basic"
        ? setLoginInformation({
            ...loginInformation,
            result: "basic",
          })
        : setLoginInformation({
            ...loginInformation,
            result: "manual",
          });

      // Go to account selection step
      nextStep("manual-policy-info");
    } catch (error) {
      // If 500 send customer to "failed"
      if (
        error.message === "Oops! Something went wrong." ||
        error.message === "Oops something went wrong. Please try again later."
      ) {
        nextStep("carrier-error");
      }
      // Else display error
      setError(error.message);
    }
  };

  return (
    <>
      <div className="flex">
        <div
          className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
          aria-label="Axle"
        ></div>
        <div
          style={{
            backgroundImage: `url("${
              hasCarrier
                ? get(loginInformation, "carrier.image")
                : `https://axle-labs-assets.s3.amazonaws.com/carrierLogos/generic.svg`
            }")`,
            // backgroundColor: loginInformation.carrier.color,
          }}
          className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
          aria-label={
            hasCarrier
              ? get(loginInformation, "carrier.name")
              : "Unknown carrier"
          }
        ></div>
      </div>
      <div className="flex flex-col gap-y-2">
        <h3 className="text-xl text-black font-bold">
          Enter your policy holder's information
        </h3>
        <p className="text-base text-black">
          {hasCarrier ? (
            <>
              Axle will provide this information if we’re unable to connect with{" "}
              <b>{loginInformation.carrier.name}</b>.
            </>
          ) : (
            <>Axle will use this information to verify your policy details.</>
          )}
        </p>
      </div>
      <form className="flex flex-col gap-y-8" onSubmit={onSubmit}>
        <div className="flex flex-col gap-y-4 overflow-y-auto">
          {error && (
            <div
              className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
              role="status"
            >
              {" "}
              {error}{" "}
            </div>
          )}
          {!hasCarrier && (
            <input
              placeholder="Carrier"
              value={carrierName}
              className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
              type="text"
              onChange={(e) => setCarrierName(e.target.value)}
            />
          )}
          <input
            placeholder="First name"
            value={firstName}
            className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            placeholder="Last name"
            value={lastName}
            className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
            type="text"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-y-6 mt-auto">
          <hr className="-mx-8 border-gray-100" />
          <ButtonPrimary text={"Continue"} width={"w-full"} type={"submit"} />
        </div>
      </form>
    </>
  );
};

export default ManualAccount;
