/**
 * These components are styled as buttons but used when we are redirecting the user to an
 * external page, e.g. when they forgot their password or need to set up an account
 */

const AnchorPrimary = ({ text, url, onClick, width }) => {
  return (
    <>
      <a
        href={url}
        target="_blank"
        onClick={onClick}
        className={`text-center rounded-sm p-3 ${width} text-white focus:bg-gray-500 text-base bg-black`}
      >
        {text}
      </a>
    </>
  );
};

const AnchorSecondary = ({ text, url, onClick, width, outlined = false }) => {
  const anchorOutline = outlined
    ? `text-center rounded-sm p-3 ${width} text-black focus:bg-gray-500 text-base bg-white border border-solid border-black`
    : `text-center text-base font-medium text-gray-500 hover:text-black focus:text-black`;
  return (
    <>
      <a href={url} className={anchorOutline} onClick={onClick} target="_blank">
        {text}
      </a>
    </>
  );
};

AnchorPrimary.defaultProps = {
  width: "w-auto",
};

AnchorSecondary.defaultProps = {
  width: "w-auto",
};

export { AnchorPrimary, AnchorSecondary };
