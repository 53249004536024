import { get } from "lodash";
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { ButtonPrimary, ButtonSecondary } from "../components/Button";
import { updateIgnition } from "../lib/axle";
import {
  generateLinkErrorMessage,
  generateManualErrorMessage,
} from "../lib/utility";

const Error = ({
  client,
  session,
  nextStep,
  step,
  previousStep,
  loginInformation,
  linkError,
  manualError,
  posthog,
}) => {
  useEffect(() => {
    posthog.capture("$pageview", {
      step,
      carrier: get(loginInformation, "carrier.id"),
    });
    // Updates ignition with error data on carrier-error condition
    const fail = async () => {
      try {
        let errorMessage = null;
        if (step === "carrier-error") {
          errorMessage = generateLinkErrorMessage(linkError);
        } else if (step === "manual-document-error") {
          errorMessage = generateManualErrorMessage(manualError);
        }
        // Construct webhook event
        const lastEvent = {
          id: `event_${nanoid()}`,
          type: `ignition.errored`,
          data: {
            token: session.id,
            message: errorMessage,
            user: session.user,
            metadata: session.metadata,
            client: client.id,
          },
          createdAt: new Date().toISOString(),
        };
        const params = {
          status: "errored",
          lastEvent,
        };
        await updateIgnition(session.id, params);
      } catch (error) {
        console.error(error.message);
      }
    };
    trackPromise(fail());
  }, [
    step,
    client.id,
    session.id,
    session.config,
    session.user,
    session.metadata,
    linkError,
    posthog,
    loginInformation,
  ]);

  const error = {
    "carrier-error": {
      illustration: "manual-required",
      header: "Hmm. Something’s not right.",
      subheader: "We're unable to connect to your carrier right now.",
      primary: {
        text: get(session, "config.carrier-error.primary.text") ?? "Continue",
        step: `${
          get(session, "config.manual.enabled") ? "manual-account" : "failed"
        }`,
      },
      secondary: {
        text:
          get(session, "config.carrier-error.secondary.text") ?? "Try again",
        step: previousStep === "enterMfa" ? "login" : previousStep,
      },
    },
    "manual-document-error": {
      illustration: "manual-required",
      header: "Hmm. Something’s not right.",
      subheader: "We're unable to process your document right now.",
      primary: {
        text: "Try again",
        step: previousStep,
      },
      secondary: {
        text: "Continue",
        step: "failed",
      },
    },
  };

  return (
    <>
      <div className="flex flex-col grow gap-y-8 mt-16">
        <div className="flex justify-center">
          <div
            style={{
              backgroundImage: `url("./icons/${error[step].illustration}.svg")`,
            }}
            className={`flex-none h-32 w-full bg-contain bg-no-repeat bg-center`}
            aria-label={step}
          ></div>
        </div>
        <div className="flex flex-col justify-center gap-y-4 text-center text-black">
          <h3 className="font-bold text-xl"> {error[step].header} </h3>
          <p className="text-xl"> {error[step].subheader} </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 mt-auto">
        {error[step].primary && (
          <ButtonPrimary
            text={error[step].primary.text}
            onClick={() => nextStep(error[step].primary.step)}
            width={"w-full"}
          />
        )}
        {error[step].secondary && (
          <ButtonSecondary
            onClick={() => nextStep(error[step].secondary.step)}
            text={error[step].secondary.text}
          />
        )}
      </div>
    </>
  );
};

export default Error;
