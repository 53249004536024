import { RadioGroup } from "@headlessui/react";
import _, { get } from "lodash";
import { useEffect, useState } from "react";

import {
  ButtonPrimary,
  ButtonSecondary,
  RadioButton,
} from "../components/Button";

const EXIT_SURVEY_NAME = "Exit Survey";

const choiceRadio = (options, option, setOption, onClick) => {
  return (
    <RadioGroup
      value={option}
      onChange={setOption}
      className="flex flex-col gap-y-4"
    >
      {options.map((option, index) => (
        <RadioGroup.Option
          onClick={() => onClick(option)}
          key={index}
          value={option}
          className={({ active, checked }) =>
            `flex text-left items-center text-black rounded group ${
              active
                ? "text-white border border-solid border-black bg-black  p-4 filter shadow transition-colors cursor-pointer"
                : ""
            }
     ${
       checked
         ? "text-white border border-solid border-black bg-black p-4 filter shadow transition-colors cursor-pointer"
         : "hover:text-white focus:text-white border border-solid border-black bg-white hover:bg-black focus:bg-black p-4 filter shadow transition-colors cursor-pointer"
     }
     `
          }
        >
          {({ active, checked }) => (
            <div className="flex gap-x-3 items-center">
              <div className="">
                {checked ? (
                  <RadioButton checked className="h-6 w-6 " />
                ) : (
                  <RadioButton active className="h-6 w-6 " />
                )}
              </div>

              <div className="flex ">
                <h3 className="text-base ">{option}</h3>
              </div>
            </div>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export const ExitSurvey = ({ step, nextStep, posthog, session }) => {
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState("");
  const [survey, setSurvey] = useState("");
  const [surveyName, setSurveyName] = useState("");
  const [surveyQuestion, setSurveyQuestion] = useState("");

  // releaseCondition: 0-1 value to determine how much of the traffic should see the survey
  // release condition from posthog is not used AT ALL. By default, we set it to 50% if
  // ignition config doesn't contain any exit survey configs
  const releaseCondition = parseFloat(
    get(session, "config.exit-survey.releaseCondition", "0.5")
  );
  const randomNumber = _.random(0, 1, true);

  useEffect(() => {
    posthog.capture("$pageview", {
      step,
    });

    posthog.getActiveMatchingSurveys((surveys) => {
      const filteredSurveys = surveys.filter(
        (survey) => get(survey, "name") === EXIT_SURVEY_NAME
      );
      if (filteredSurveys.length > 0) {
        // show the survey using your custom UI
        setOptions(get(filteredSurveys[0], "questions[0].choices"));
        setSurvey(get(filteredSurveys[0], "id"));
        setSurveyName(get(filteredSurveys[0], "name"));
        setSurveyQuestion(get(filteredSurveys[0], "questions[0].question"));
      }
    });
  }, [posthog]);

  useEffect(() => {
    if (posthog && survey) {
      posthog.capture(`survey shown`, {
        $survey_id: survey, // required
      });
    }

    if (releaseCondition > randomNumber) {
      nextStep("exit");
    }
  }, [survey, posthog]);

  const onClick = (selectedOption) => {
    setOption(selectedOption);

    posthog.capture("survey sent", {
      $survey_id: survey, // required
      $survey_response: selectedOption, // required
    });
  };

  return (
    <>
      <div className="flex flex-col gap-y-6">
        <h3 className="text-xl text-black font-bold">{surveyQuestion}</h3>
      </div>
      <div className="flex flex-col gap-y-4 overflow-y-auto -mb-8 pb-8">
        {choiceRadio(options, option, setOption, onClick)}
      </div>

      <div className="flex flex-col gap-y-6 mt-auto">
        <hr className="-mx-8 border-gray-10 [box-shadow:0px_-1px_2px_rgba(0,_0,_0,_0.06)]" />
        <ButtonPrimary
          text={"Finish"}
          width={"w-full"}
          type={"submit"}
          onClick={() => nextStep("exit")}
        />
        <ButtonSecondary
          onClick={() => nextStep("exit")}
          text={"Skip survey"}
        />
      </div>
    </>
  );
};
