import Logo from "../icons/Logo";

const NavIcon = ({
  name,
  translate,
  color,
  className,
  h,
  w,
  onClick,
  ariaLabel,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        maskImage: `url("./icons/${name}.svg")`,
        WebkitMaskImage: `url("./icons/${name}.svg")`,
      }}
      className={`${className} ${h} ${w} ${color} ${translate} flex-none transform transition-all `}
      aria-label={ariaLabel}
    ></button>
  );
};

NavIcon.defaultProps = {
  h: "h-4",
  w: "w-4",
  translate: "translate-y-1",
  color: "bg-black",
};

const Nav = ({ step, nextStep, prevStep, showPrevStep, showExit }) => {
  return (
    <nav>
      <div className="flex align-middle justify-between flex-row-reverse">
        <NavIcon
          name="close"
          translate="translate-y-0"
          className={`cursor-pointer hover:scale-125 focus:bg-gray-500 focus:scale-125 active:bg-black ${
            !showExit ? "opacity-0 pointer-events-none" : null
          }`}
          onClick={(e) => {
            nextStep("exit-confirmation");
            e.currentTarget.blur(); // Necessary to remove focus style after button is selected
          }}
          ariaLabel="Exit"
        />
        <Logo className={`${step === "consent" ? "hidden" : null}`} h="16" />
        <NavIcon
          name="back"
          translate="translate-y-0"
          className={`cursor-pointer hover:scale-125 focus:bg-gray-500 focus:scale-125 active:bg-black  ${
            !showPrevStep ? "opacity-0 pointer-events-none" : null
          }`}
          onClick={(e) => {
            prevStep();
            e.currentTarget.blur(); // Necessary to remove focus style after button is selected
          }}
          ariaLabel="Back"
        />
      </div>
    </nav>
  );
};

export default Nav;
