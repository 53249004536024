const Icon = ({
  name,
  translate,
  color,
  className,
  h,
  w,
  onClick,
  ariaLabel,
  tabIndex,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        maskImage: `url("./icons/${name}.svg")`,
        WebkitMaskImage: `url("./icons/${name}.svg")`,
      }}
      className={`${className} ${h} ${w} ${color} ${translate} flex-none transform transition-all `}
      aria-label={ariaLabel}
      tabIndex={tabIndex ? tabIndex : ""}
    ></div>
  );
};

Icon.defaultProps = {
  h: "h-4",
  w: "w-4",
  translate: "translate-y-1",
  color: "bg-black",
};

export default Icon;
