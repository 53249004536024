import { RadioGroup } from "@headlessui/react";
import _, { get, size } from "lodash";
import { DateTime } from "luxon";
import { RadioButton } from "./Button";

const maskPolicyNumber = (policy) => {
  const policyNumber = get(policy, "policyNumber", "");
  const policyNumberLength = size(policyNumber);

  const maskedSubPolicyNumber = "•".repeat(Math.max(policyNumberLength - 4, 0));
  const unmaskedSubPolicyNumber = !_.isEmpty(policyNumber)
    ? policyNumber.substring(
        Math.max(policyNumberLength - 4, 0),
        policyNumberLength
      )
    : "";

  const maskedPolicyNumber = `${maskedSubPolicyNumber} ${unmaskedSubPolicyNumber}`;

  return maskedPolicyNumber;
};

const Policy = ({ policy, onClick }) => {
  const titleCase = (inputString) => {
    return _.startCase(_.toLower(inputString));
  };

  const maskedPolicyNumber = maskPolicyNumber(policy);

  return (
    <RadioGroup.Option
      onClick={onClick}
      key={get(policy, "policyNumber")}
      value={policy}
      className={({ active, checked }) =>
        `flex items-center text-black rounded group ${
          active
            ? "text-white border border-solid border-black bg-black  p-4 filter shadow transition-colors cursor-pointer"
            : ""
        }
          ${
            checked
              ? "text-white border border-solid border-black bg-black p-4 filter shadow transition-colors cursor-pointer"
              : "hover:text-white border border-solid border-black bg-white hover:bg-black p-4 filter shadow transition-colors cursor-pointer"
          }
          `
      }
    >
      {({ active, checked }) => (
        <>
          <div className="flex-grow">
            <div className="flex">
              <h3 className="text-base font-bold">
                {titleCase(get(policy, "policyType"))}
                {"  "}
                {maskedPolicyNumber}
              </h3>
            </div>
            <div className="flex flex-col gap-y-0">
              <p className=" text-base">{get(policy, "policyHolder", "")} </p>
              <p className="text-base">
                {titleCase(get(policy, "policyLabel", ""))}
              </p>{" "}
              <p className="text-base ">
                {get(policy, "effectiveDate")
                  ? DateTime.fromISO(
                      get(policy, "effectiveDate", "")
                    ).toLocaleString(DateTime.DATE_MED)
                  : "n/a"}{" "}
                -{" "}
                {get(policy, "expirationDate")
                  ? DateTime.fromISO(
                      get(policy, "expirationDate")
                    ).toLocaleString(DateTime.DATE_MED)
                  : "n/a"}
              </p>
            </div>
          </div>
          <div className="flex">
            {checked ? (
              <RadioButton checked className="h-6 w-6" />
            ) : (
              <RadioButton active className="h-6 w-6" />
            )}
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
};

export default Policy;
