import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useState, useEffect } from "react";
import { get } from "lodash";

import { getMfaOptions, sendMfa } from "../lib/axle";
import CarrierLoader from "./CarrierLoader";
import Radio from "../components/Radio";

const SendMfa = ({
  step,
  nextStep,
  setLoginInformation,
  loginInformation,
  session,
  setLinkError,
  posthog,
  setShowNav,
}) => {
  const carrier = get(loginInformation, "carrier.id");
  const ignitionToken = session.id;

  const [error, setError] = useState(null);
  let [mfaOptions, setMfaOptions] = useState([]);

  // Configure carrier loader
  const { promiseInProgress } = usePromiseTracker({ area: "carrier-loader" });
  const loadingSteps = [
    "Establishing a secure connection",
    "Contacting your carrier",
    "Retrieving verification options",
  ];

  const onClick = async (index) => {
    let { type, id } = mfaOptions[index];

    try {
      // Send MFA code
      await sendMfa(ignitionToken, carrier, type, id);

      // Save MFA type to state
      setLoginInformation({ ...loginInformation, mfa: { type } });

      // Go to MFA code entry step
      nextStep("enterMfa");
    } catch (error) {
      switch (error.code) {
        // If session expired, send customer to session-expired
        case 401:
        case 403:
          setLinkError(error.message);
          nextStep("session-expired");
          return;
        default:
          setLinkError("send MFA code");
          nextStep("carrier-error");
          return;
      }
    }
  };

  const fetchMfaOptions = async () => {
    // Hide nav buttons while CarrierLoader is rendered for accessibility
    setShowNav(false);

    try {
      const mfaOptions = await getMfaOptions(ignitionToken, carrier);
      setMfaOptions(mfaOptions);
    } catch {
      switch (error.code) {
        // If session expired, send customer to session-expired
        case 401:
        case 403:
          setLinkError(error.message);
          nextStep("session-expired");
          return;
        // If 500 send customer to "failed"
        case 500:
          setLinkError("retrieve MFA options");
          nextStep("carrier-error");
          return;
        default:
          setError(error.message);
          return;
      }
    }
  };

  useEffect(() => {
    // Track pageview
    posthog.capture("$pageview", { step, carrier });

    trackPromise(fetchMfaOptions(), "carrier-loader");

    // Show nav buttons after CarrierLoader is unrendered
    setShowNav(true);
  }, [posthog]);

  return (
    <>
      {promiseInProgress ? (
        <CarrierLoader
          loginInformation={loginInformation}
          loadingHeader="Retrieving verification options"
          loadingSteps={loadingSteps}
        />
      ) : (
        <>
          <div className="flex">
            <div
              className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
              aria-label="Axle"
            ></div>
            <div
              style={{
                backgroundImage: `url("${loginInformation.carrier.image}")`,
                // backgroundColor: loginInformation.carrier.color,
              }}
              className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
              aria-label={loginInformation.carrier.name}
            ></div>
          </div>
          <div className="flex flex-col gap-y-2">
            <h3 className="text-xl text-black font-bold">
              Verify your identity
            </h3>
            <p className="text-base text-black">
              <b>{loginInformation.carrier.name}</b> would like to verify your
              identity. How would you like to receive a verification code?
            </p>
          </div>
          <div className="flex flex-col gap-y-4 overflow-y-auto -mb-8 pb-8">
            {error ? (
              <div
                className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
                role="status"
              >
                {" "}
                {error}{" "}
              </div>
            ) : mfaOptions.length ? (
              mfaOptions.map((mfaOption, index) => (
                <Radio
                  key={index}
                  title={mfaOption.type}
                  subtext={mfaOption.label}
                  onClick={() => onClick(index)}
                />
              ))
            ) : (
              <h1>No verification options found.</h1>
            )}
          </div>
          {/* <div className="absolute block bottom-0 left-0 w-full h-14 bg-gradient-to-t from-white"></div> */}
        </>
      )}
    </>
  );
};

export default SendMfa;
