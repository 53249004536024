import imageCompression from "browser-image-compression";
import _ from "lodash";
import { parsePhoneNumber } from "react-phone-number-input";

export const constructUriWithSearchParams = (urlAsString, paramsToAdd) => {
  let urlAsObject = new URL(urlAsString);

  for (const [key, value] of Object.entries(paramsToAdd)) {
    urlAsObject.searchParams.append(key, value);
  }

  return urlAsObject.toString();
};

export const generateLinkErrorMessage = (linkError) => {
  return `The Axle service could not ${linkError} for the specified carrier.`;
};

export const generateManualErrorMessage = (manualError) => {
  return `The Axle service could not ${manualError} the uploaded document`;
};

export const checkZipCode = (str) => {
  // Check if the string consists of exactly 5 numeric characters
  return /^\d{5}$/.test(str);
};

export const getUserAndMetadataFromQueryParams = (obj, userKeys) => {
  const extractedUserItems = _.pick(obj, userKeys);
  const extractedMetadataKeys = _.difference(_.keys(obj), userKeys);
  const extractedMetadataItems = _.pick(obj, extractedMetadataKeys);

  // Parse phone number
  if (extractedUserItems.phone) {
    extractedUserItems.phone = parsePhoneNumber(
      extractedUserItems.phone,
      "US"
    )?.number;
  }

  // Return extracted user fields
  return {
    userQueryParams: extractedUserItems,
    metadataQueryParams: extractedMetadataItems,
  };
};

/**
 *
 * @param {*} file File object to compress
 * @param {*} maxMegaPixels Max number of megapixels in the image
 * @param {*} mimeType Mime type of the image
 * @returns
 */
export const compressImage = async (file, maxMegaPixels, mimeType) => {
  const megaPixelCompressedImage = await compressImageToMaxPixels(
    file,
    maxMegaPixels
  );

  // Create an object URL and assign it to the file
  const preview = URL.createObjectURL(megaPixelCompressedImage);
  const fileWithPreview = Object.assign(megaPixelCompressedImage, { preview });
  const options = {
    maxSizeMb: 5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    mimeType,
  };

  console.log(`Compressing image to 5 MB...`);
  const compressedImage = await imageCompression(fileWithPreview, options);

  // Release the object URL
  URL.revokeObjectURL(preview);
  return compressedImage;
};

export const compressImageToMaxPixels = (file, maxMegaPixels) => {
  console.log(`Compressing image to ${maxMegaPixels} megapixels...`);
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    // Load image into canvas and scale down if necessary
    image.onload = () => {
      // Revoke object URL once image is loaded
      URL.revokeObjectURL(image.src);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let width = image.width;
      let height = image.height;

      const currentMegaPixels = (width * height) / 1000000;

      // If current number of megapixels is more than our max limit, scale down
      if (currentMegaPixels > maxMegaPixels) {
        const scaleFactor = Math.sqrt(maxMegaPixels / currentMegaPixels);
        width *= scaleFactor;
        height *= scaleFactor;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(
        (blob) => {
          // Return scaled down image
          resolve(new File([blob], file.name, { type: file.type }));
        },
        file.type,
        0.9
      );
    };

    image.onerror = (error) => {
      URL.revokeObjectURL(image.src);
      console.error(error);
      reject(error);
    };
  });
};
