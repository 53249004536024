const InterstitialButtonPrimary = ({
  width,
  text,
  type,
  onClick,
  name,
  color,
}) => {
  return (
    <>
      <button
        name={name}
        onClick={onClick}
        className={`rounded-sm p-3 ${width} text-white focus:bg-gray-500 text-base`}
        style={{ backgroundColor: color ?? "#000000" }}
        type={type}
      >
        {text}
      </button>
    </>
  );
};

const ButtonPrimary = ({ width, text, type, onClick, name }) => {
  return (
    <>
      <button
        name={name}
        onClick={onClick}
        className={`rounded-sm p-3 ${width} text-white focus:bg-gray-500 text-base bg-black`}
        type={type}
      >
        {text}
      </button>
    </>
  );
};

const ButtonSecondary = ({ text, onClick, name, type, outlined = false }) => {
  const buttonColor = outlined
    ? `rounded-sm p-3 w-full text-black focus:bg-gray-500 text-base bg-white border border-solid border-black`
    : `text-base font-medium text-gray-500 hover:text-black focus:text-black`;

  return (
    <>
      <button
        name={name}
        onClick={onClick}
        className={buttonColor}
        type={type ?? "button"}
      >
        {text}
      </button>
    </>
  );
};

const RadioButton = (policyState) => {
  const radioHover = (
    <circle
      className={"stroke-black group-hover:stroke-white group-hover:stroke-6"}
      cx="10.5"
      cy="10"
      r={"9"}
    />
  );

  const innerCircle =
    "active" in policyState ? (
      radioHover
    ) : (
      <circle cx="10.5" cy="10" r="7" stroke="white" strokeWidth="6" />
    );

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        {innerCircle}
      </svg>
    </div>
  );
};

InterstitialButtonPrimary.defaultProps = {
  width: "w-auto",
};

ButtonPrimary.defaultProps = {
  width: "w-auto",
};

ButtonSecondary.defaultProps = {
  width: "w-auto",
};

export {
  ButtonPrimary,
  ButtonSecondary,
  InterstitialButtonPrimary,
  RadioButton,
};
