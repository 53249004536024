import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { generateSignedUrl, updatePolicy } from "../lib/axle";
import { ManualDropzone } from "../components/ManualDropzone";
import React from "react";

const ManualPolicyDocument = ({
  step,
  nextStep,
  accountInfo,
  setAccountInfo,
  policyInfo,
  setPolicyInfo,
  loginInformation,
  setLoginInformation,
  session,
  posthog,
  setManualError,
  setShowNav,
}) => {
  const ignitionToken = session.id;
  //Local error state used only within this component.
  //Just replaced within component [error, setError] = useState(null);
  //with [documentStatus, setDocumentStatus] = useState(null);
  const [documentStatus, setDocumentStatus] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [filePath, setFilePath] = useState("");
  const [hasFile, setHasFile] = useState(false);

  let loadingSteps = ["Uploading your document"];

  const uploadWrapper = async () => {
    if (!hasFile) {
      alert("Please upload a document!");
      return;
    }

    // Hide nav buttons while CarrierLoader is rendered for accessibility
    setShowNav(false);

    try {
      const signedUrlResponse = await generateSignedUrl(
        ignitionToken,
        policyInfo.id,
        filePath
      );

      const documentUploadResponse = await fetch(signedUrlResponse.signedUrl, {
        method: "PUT",
        body: fileData,
      });

      if (documentUploadResponse.status !== 200) {
        console.error(`HTTP Error: ${documentUploadResponse.status}`);
        throw new Error(
          `Failed to upload: ${documentUploadResponse.statusText}`
        );
      }

      const documentKey = signedUrlResponse.objectKey;
      let documentsObject = [
        {
          source: "user",
          name: filePath,
          key: documentKey,
          createdAt: new Date().toISOString(),
          type: null,
          effectiveDate: null,
          issuedDate: null,
        },
      ];

      let policyId = policyInfo.id;
      let accountId = accountInfo.id;

      setLoginInformation({
        ...loginInformation,
        result: "manual",
      });

      // Setting account and policy info
      setAccountInfo({ id: accountId });
      setPolicyInfo({ id: policyId });

      // "Updating policy with documentKey...
      await updatePolicy(ignitionToken, policyId, accountId, documentsObject);

      setDocumentStatus("success");

      nextStep("success");
      return;
    } catch (error) {
      setManualError(error.message); //App.js level error state
      nextStep("manual-document-error");
      return;
    }
  };

  const uploadFile = async () => {
    await trackPromise(uploadWrapper(), "carrier-loader");

    // Show nav buttons after CarrierLoader is unrendered
    setShowNav(true);
  };

  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  return (
    <>
      <ManualDropzone
        session={session}
        loginInformation={loginInformation}
        uploadFile={uploadFile}
        loadingSteps={loadingSteps}
        setFileData={setFileData}
        setFilePath={setFilePath}
        setHasFile={setHasFile}
        documentStatus={documentStatus}
      />
    </>
  );
};

export default ManualPolicyDocument;
