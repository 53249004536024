import _, { get } from "lodash";
import { useEffect, useRef, useState } from "react";

import { ButtonPrimary, ButtonSecondary } from "../components/Button";
import CarrierLoader from "./CarrierLoader";

import Icon from "../components/Icon";
import { getAccount, login, updateIgnition } from "../lib/axle";
import { checkZipCode } from "../lib/utility";

const WEBSOCKET_ENDPOINT_MAP = {
  prod: "wss://50tsqwg91m.execute-api.us-east-1.amazonaws.com/prod/",
  dev: "wss://c1zq92dq49.execute-api.us-east-1.amazonaws.com/dev/",
  sandbox: "wss://81b2mex1c2.execute-api.us-east-1.amazonaws.com/sandbox/",
};

const Login = ({
  step,
  nextStep,
  setAccountInfo,
  loginInformation,
  setLoginInformation,
  session,
  previousStep,
  setLinkError,
  posthog,
  loginAttempts,
  setLoginAttempts,
  setShowNav,
}) => {
  const handleUnsuccessfulGetAccount = (error) => {
    switch (error.code) {
      case 400:
        if (
          error.message ===
          "Sorry, your insurance account or policy has not been set up for online access."
        ) {
          nextStep("account-pending");
          return;
        }
      // If session expired, send customer to session-expired. Change 7/10/23 to back to carrier-error
      case 401:
      case 403:
        setLinkError(error.message);
        nextStep("carrier-error");
        return;
      // If 500 send customer to "failed"
      case 500:
        setLinkError("retrieve account details");
        nextStep("carrier-error");
        return;
      case 503:
        setLinkError(error.message);
        nextStep("carrier-maintenance");
        return;
      default:
        // Must return here to prevent getAccount from being called
        setError(error.message);
        setPromiseInProgress(false);
        return;
    }
  };

  const handleUnsuccessfulLogin = async (error) => {
    switch (error.message) {
      case "Username not found. Please try again":
      case "Username or password incorrect. Please try again":
        // Increment loginAttempt counter and display remaining attempts if incorrect username/password

        // Send to max login attempts if 3 or more unsuccessful attempts
        if (get(loginAttempts, carrier, 0) + 1 > 2) {
          nextStep("max-login-attempts");
          ws.current.close();
          return;
        }

        // Increment login attempt counter
        setLoginAttempts({
          ...loginAttempts,
          [carrier]: get(loginAttempts, carrier, 0) + 1,
        });

        // Update error message
        error.message = `Username or password incorrect. You have ${
          3 - (get(loginAttempts, carrier, 0) + 1)
        } ${
          3 - (get(loginAttempts, carrier, 0) + 1) === 1
            ? "attempt"
            : "attempts"
        } remaining.`;
        setPromiseInProgress(false);
        setError(error.message);
        ws.current.close();
        return;
      case "Sorry, your account is locked. Please reset your password and then try again!":
        setPromiseInProgress(false);
        setError(error.message);
        ws.current.close();
        return;
      case "Sandbox only supports test credentials. Please try again.":
        setPromiseInProgress(false);
        setError("Sandbox only supports test credentials. Please try again.");
        ws.current.close();
        return;
      case "Sorry, your insurance account or policy has not been set up for online access.":
        nextStep("account-pending");
        ws.current.close();
        return;
      case "Sorry, your AAA chapter is not supported by Axle.":
        nextStep("aaa-chapter-error");
        ws.current.close();
        return;
      case "Sorry, this carrier is undergoing maintenance and is temporarily unavailable. Please try again later.":
        nextStep("carrier-maintenance");
        ws.current.close();
        return;
      case "Session expired": // Session expired errors on login are errors which we believe can be solved by a retry
      default:
        // If an error occurs, and retry attempts are less than 1, retry logging in
        if (retryAttempts < 1) {
          retryAttempts++;
          loadingSteps = [
            "Login is taking longer than expected.",
            "Trying to contact your carrier again.",
            "Retrieving account information.",
          ];
          console.log("Received an error from login, retrying...");
          ws.current.close();
          await loginGetAccount();
          // Else set the on-screen error message
        } else {
          setLinkError("login");
          nextStep("carrier-error");
          ws.current.close();
        }
        return;
    }
  };

  const carrier = get(loginInformation, "carrier.id");

  const ignitionToken = session.id;
  // To test loader, uncomment below and comment out usePromisetracker
  // const promiseInProgress = true;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const [promiseInProgress, setPromiseInProgress] = useState(false);
  const MAX_RETRIES = 2;
  const ws = useRef(null);

  let loadingSteps = [
    "Encrypting credentials",
    "Establishing a secure connection",
    "Contacting your carrier",
    "Retrieving account information",
  ];

  const registerUrl = get(loginInformation, "carrier.registerUrl", false);
  const recoveryUrl = get(loginInformation, "carrier.recoveryUrl", false);
  const isManualEnabled = get(session, "config.manual.enabled", false);

  const isLoginSupportEnabled = registerUrl || recoveryUrl || isManualEnabled;

  let retryAttempts = 0;

  // Form logic
  const loginGetAccount = async () => {
    let auth = null;
    let messageReceived = false;
    let timeoutId = null;

    // Remove the nav bar while loading
    setShowNav(false);
    const openWebSocket = (retries) => {
      retries++;
      console.log("Retries: " + retries);
      // Open websocket
      ws.current = new WebSocket(
        get(WEBSOCKET_ENDPOINT_MAP, process.env.REACT_APP_STAGE, "")
      );

      // Send login request once websocket is open
      ws.current.onopen = async () => {
        console.log("Websocket successfully opened.");

        // Get connectionId
        const payload = {
          action: "getConnectionId",
          data: {
            ignitionToken,
          },
        };
        console.log("Getting connectionid...");
        ws.current.send(JSON.stringify(payload));

        // If after 92s no message has been received, close the websocket.
        timeoutId = setTimeout(() => {
          if (!messageReceived) {
            console.log(
              "No message received from the server within the timeout period"
            );
            ws.current.close();
          }
        }, 92000);

        console.log(`Starting new timeout with id ${timeoutId}`);
      };

      // Handle message returned from ign-be websocket
      ws.current.onmessage = async (event) => {
        console.log(
          "Received message from ignition backend, parsing response..."
        );

        const response = JSON.parse(event.data);
        if (get(response, "connectionId")) {
          console.log("Received connectionId, calling async login...");
          const connectionId = get(response, "connectionId");

          await login(
            ignitionToken,
            carrier,
            username,
            password,
            zipcode,
            connectionId
          );
        }
        // Handle successful login
        else if (get(response, "statusCode") === 200) {
          console.log("Login was successful, parsing response...");
          // Clear timeout function, indicating that we've received a message from the server
          messageReceived = true;
          console.log(`Clearing time out ${timeoutId}`);
          clearTimeout(timeoutId);
          auth = JSON.parse(response.body);
          auth = get(auth, "data");
          // If MFA required, send to MFA
          if (auth.mfaRequired) {
            // Set login result to link
            setLoginInformation({ ...loginInformation, result: "link" });

            nextStep("sendMfa");
            ws.current.close();

            // Restore the nav bar
            setShowNav(true);
            return;
          }

          // If carrier is not found (edge case), send to manual input or unsupported carrier
          else if (auth.isNotFound) {
            // Set login result to unsupported-carrier
            setLoginInformation({
              ...loginInformation,
              result: "unsupported-carrier",
            });

            nextStep(
              isManualEnabled ? "manual-account" : "unsupported-carrier"
            );
            ws.current.close();

            // Restore the nav bar
            setShowNav(true);
            return;
          }

          // If carrier is part of basic flow, send to manual input or unsupported carrier
          else if (auth.isBasic) {
            // Set login result to basic
            setLoginInformation({ ...loginInformation, result: "basic" });

            nextStep(
              _(session).get("config.basic.manual") && isManualEnabled
                ? "manual-account"
                : "basic-policy-info"
            );
            ws.current.close();

            // Restore the nav bar
            setShowNav(true);
            return;
          }
          try {
            // Fetch account overview
            const account = await getAccount(ignitionToken);

            // If no compatible policies on account send to "no-policies"
            if (_(account.policies).size() === 0) {
              nextStep("no-policies");
              ws.current.close();
              return;
            }

            // Save account info to state
            setAccountInfo(account);

            // Set login result to link
            setLoginInformation({ ...loginInformation, result: "link" });
            // Go to account selection step
            nextStep("confirm");
            console.log(
              "Closing the websocket after successful login and getAccount..."
            );
            ws.current.close();

            // Restore the nav bar
            setShowNav(true);
            return;
          } catch (error) {
            handleUnsuccessfulGetAccount(error);
            ws.current.close();

            // Restore the nav bar
            setShowNav(true);
          }
        }
        // Handle failed login
        else {
          console.log("Login did not return a 200 status code...");
          // Clear timeout function, indicating that we've received a message from the server
          messageReceived = true;
          console.log(`Clearing time out ${timeoutId}`);
          clearTimeout(timeoutId);
          const error = JSON.parse(response.body);
          await handleUnsuccessfulLogin(error);

          // Restore the nav bar
          setShowNav(true);
        }
      };

      // Handle websocket close
      ws.current.onclose = () => {
        console.log("Websocket closed successfully");
        // If we haven't gotten a message but websocket retry count is under max_retries, retry
        if (!messageReceived && retries < MAX_RETRIES) {
          console.log(`Clearing time out ${timeoutId}`);
          clearTimeout(timeoutId);
          console.log(
            "Have not received a message from the server, attempting to reopen the websocket connection again, number of tries: " +
              retries
          );
          openWebSocket(retries);

          // If we haven't gotten a message and we've hit the max retry count, take user to error page
        } else if (!messageReceived && retries >= MAX_RETRIES) {
          console.log(`Clearing time out ${timeoutId}`);
          clearTimeout(timeoutId);

          console.log(
            "Have not received a message from the server, and hit max retries. Taking user to error page..."
          );
          setLinkError("login");
          nextStep("carrier-error");

          // Restore the nav bar
          setShowNav(true);
          return;
        }
      };

      // Handle connection error
      ws.current.onerror = (error) => {
        console.error("Websocket connection error: ", JSON.stringify(error));

        // Clear timeout and let onclose() handle retry logic
        clearTimeout(timeoutId);
        ws.current.close();
      };
    };

    openWebSocket(0);
  };

  const onSubmit = async (e) => {
    // Prevent default form behavior
    e.preventDefault();

    if (
      get(loginInformation, "carrier.id") === "aaa" &&
      !checkZipCode(zipcode)
    ) {
      setError("Please enter a valid zipcode!");
      return;
    }

    // Validate input requirements
    if (!username) {
      setError("Please add a username!");
      return;
    }

    if (!password) {
      setError("Please add a password!");
      return;
    }

    // Track login submit
    // Only track if a username and password are entered
    posthog.capture("login-submit", {
      step,
      carrier,
      loginAttempt: get(loginAttempts, carrier, 0) + 1,
    });

    setPromiseInProgress(true);
    await loginGetAccount();
  };

  // Setup login state
  useEffect(() => {
    posthog.capture("$pageview", { step, carrier });

    // Update ignition session with carrier
    const updateIgnitionLogin = async () => {
      await updateIgnition(session.id, {
        carrier,
      });
    };
    updateIgnitionLogin();

    // Get updated session
    const getLoginAttempts = async () => {
      // Reset login attempts if the user is coming from max attempts page
      if (previousStep === "max-login-attempts") {
        setLoginAttempts((prevAttempts) => ({
          ...prevAttempts,
          [carrier]: 0,
        }));
      } else {
        // Send to max login attempts if 3 or more unsuccessful attempts
        if (get(loginAttempts, carrier) > 2) {
          nextStep("max-login-attempts");
          ws.current.close();
          return;
        }

        // Set remaining attempts error message if any unsuccessful attempts
        if (get(loginAttempts, carrier) > 0) {
          setError(
            `Username or password incorrect. You have ${
              3 - get(loginAttempts, carrier, 0)
            } ${
              3 - get(loginAttempts, carrier, 0) === 1 ? "attempt" : "attempts"
            } remaining.`
          );
        }

        // Update login attempts
        setLoginAttempts({
          ...loginAttempts,
          [carrier]: get(loginAttempts, carrier, 0),
        });
      }
    };
    getLoginAttempts();
  }, [posthog]);

  return (
    <>
      {promiseInProgress ? (
        <CarrierLoader
          loginInformation={loginInformation}
          loadingHeader="Logging in"
          loadingSteps={loadingSteps}
        />
      ) : (
        <>
          <div className="grow flex flex-col gap-8">
            <div className="flex">
              <div className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"></div>
              <div
                style={{
                  backgroundImage: `url("${loginInformation.carrier.image}")`,
                  // backgroundColor: loginInformation.carrier.color,
                }}
                className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
                aria-label={loginInformation.carrier.name}
              ></div>
            </div>
            <div className="flex flex-col gap-y-2">
              <h3 className="text-xl text-black font-bold">
                Enter your login information
              </h3>
              <p className="text-lg text-black">
                Providing your <b>{loginInformation.carrier.name}</b> login
                information enables Axle to securely connect to your carrier.
              </p>
            </div>
            <form className="flex flex-col gap-y-8" onSubmit={onSubmit}>
              <div className="flex flex-col gap-y-6">
                {error && (
                  <div
                    className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
                    role="status"
                  >
                    {" "}
                    {error}{" "}
                  </div>
                )}

                <input
                  placeholder="Username"
                  value={username}
                  className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                  type="text"
                  autocapitalize="off"
                  autocorrect="off"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <div className=" relative flex flex-row border border-solid border-black rounded-sm items-center">
                  <input
                    placeholder="Password"
                    value={password}
                    type={passwordVisible ? "text" : "password"}
                    autocapitalize="off"
                    autocorrect="off"
                    className=" flex-grow p-3 text-base text-black placeholder-black"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Icon
                    name={passwordVisible ? "hide" : "show"}
                    className={"mr-3 cursor-pointer"}
                    h={"h-5"}
                    w={"w-5"}
                    translate={"translate-y-0"}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    ariaLabel={
                      passwordVisible ? "Hide password" : "Show password"
                    }
                  />
                </div>
                {get(loginInformation, "carrier.id") === "aaa" ? (
                  <input
                    placeholder="Policy zipcode"
                    value={zipcode}
                    className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                    type="text"
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col gap-y-4">
                <ButtonPrimary
                  text={"Connect"}
                  width={"w-full"}
                  type={"submit"}
                />

                {isLoginSupportEnabled && (
                  <ButtonSecondary
                    onClick={() => nextStep("login-support")}
                    text={"I don’t know my login information"}
                  />
                )}
              </div>
            </form>{" "}
          </div>
        </>
      )}
    </>
  );
};

export default Login;
