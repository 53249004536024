import { useEffect, useState } from "react";

const Loader = ({ isOpen }) => {
  const [scale, setScale] = useState("scale-y-0");

  useEffect(() => {
    if (isOpen) {
      // transition scale when loader is opened
      setScale("scale-y-100");
    } else {
      // clean up scale when loader is hidden
      setScale("scale-y-0");
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      setScale("scale-y-0");
    };
  }, [isOpen]);

  return (
    <>
      <div
        className={`bg-gray-50 absolute bottom-0 left-0 h-full w-full transform transition-transform z-40 origin-bottom duration-1000 ${scale}`}
      ></div>
      {isOpen ? (
        <div className="w-full h-full flex justify-center items-center absolute top-0 left-0 transition-colors z-50 ">
          {/* <div className="animate-spin h-10 w-10 border-4 rounded-full border-gray-400 border-t-black"></div> */}
          <div className="animate-ease h-10 w-10 border-4 rounded-full border-t-black border-l-gray-300 border-r-gray-300 border-gray-300"></div>
        </div>
      ) : null}
    </>
  );
};

export default Loader;
