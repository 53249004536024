import { get, toLower } from "lodash";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {
  generateSignedUrl,
  processDocuments,
  stubAccount,
  stubPolicy,
  updatePolicy,
} from "../lib/axle";
import React from "react";
import { ManualDropzone } from "../components/ManualDropzone";
import _ from "lodash";
import { compressImage } from "../lib/utility";

const MAP_FILE_EXT_TO_MIME = {
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  png: "image/png",
};

const EnhancedManualPolicyDocument = ({
  step,
  nextStep,
  accountInfo,
  setAccountInfo,
  policyInfo,
  setPolicyInfo,
  loginInformation,
  setLoginInformation,
  manualRetryCount,
  setManualRetryCount,
  session,
  posthog,
  setManualError,
  setShowNav,
}) => {
  const ignitionToken = session.id;
  //Local error state used only within this component.
  //Just replaced within component [error, setError] = useState(null);
  //with [documentStatus, setDocumentStatus] = useState(null);

  // Using set() functions to pass these down to the child component ManualDropzone
  const [documentStatus, setDocumentStatus] = useState(null);
  let [fileData, setFileData] = useState(null);
  const [filePath, setFilePath] = useState("");
  const [hasFile, setHasFile] = useState(false);

  const loadingSteps = [
    "Uploading your document",
    "Extracting data from your document",
    "Processing extracted data",
  ];

  const uploadWrapper = async () => {
    if (!hasFile) {
      alert("Please upload a document!");
      return;
    }

    // Hide nav buttons while CarrierLoader is rendered for accessibility
    setShowNav(false);

    try {
      const signedUrlResponse = await generateSignedUrl(
        ignitionToken,
        policyInfo.id,
        filePath
      );

      // If user uploads an image, we need to make sure the image is within our size and pixel constraints
      if (!toLower(filePath).includes(".pdf")) {
        const fileExtension = _.chain(filePath)
          .split(".")
          .last()
          .toLower()
          .value();
        const blob = new Blob([fileData], {
          type: get(MAP_FILE_EXT_TO_MIME, fileExtension),
        });

        // Create a File object from the Blob
        let file = new File([blob], filePath, {
          type: get(MAP_FILE_EXT_TO_MIME, fileExtension),
        });

        // Compress image to at most 40 megapixels and 5 MB
        const compressedImage = await compressImage(
          file,
          40,
          get(MAP_FILE_EXT_TO_MIME, fileExtension)
        );
        // Need to manually assign the value along with set() because set() is asynchronous and we need to use the
        // compressed image in the next fetch() call.
        fileData = compressedImage;
        setFileData(fileData);
      }

      const documentUploadResponse = await fetch(signedUrlResponse.signedUrl, {
        method: "PUT",
        body: fileData,
      });

      if (documentUploadResponse.status !== 200) {
        console.error(`HTTP Error: ${documentUploadResponse.status}`);
        throw new Error(
          `Failed to upload: ${documentUploadResponse.statusText}`
        );
      }

      const documentKey = signedUrlResponse.objectKey;
      let documentsObject = [
        {
          source: "user",
          name: filePath,
          key: documentKey,
          createdAt: new Date().toISOString(),
          type: null,
          effectiveDate: null,
          issuedDate: null,
        },
      ];

      let policyId = policyInfo.id;
      let accountId = accountInfo.id;

      setLoginInformation({
        ...loginInformation,
        result: "manual",
      });

      // Process documents via autopilot, which will handle the classification and
      // validation of the document. Call processDocuments if retry count is < 2
      if (manualRetryCount < 2) {
        try {
          // Returns account ID, policy ID, upserts documents in processDocuments
          const response = await processDocuments(
            ignitionToken,
            documentKey,
            documentsObject
          );
          accountId = get(response, "data.accountId", "");
          policyId = get(response, "data.policyId", "");
        } catch (e) {
          console.log(
            "Processing documents via autopilot failed with retry: ",
            manualRetryCount
          );

          throw e;
        }

        // If we have exceeded the manual retry count, update the policy with the document and go to success
      } else {
        // Stub account and policy
        console.log(
          "Stubbing account and policy, then updating the policy with the document on the 2nd retry..."
        );
        const accountStubbed = await stubAccount(ignitionToken, {
          carrier: get(loginInformation, "carrier.id", "Other"),
        });

        const policyStubbed = await stubPolicy(ignitionToken, {
          account: accountStubbed.id,
          carrier: get(loginInformation, "carrier.id", "Other"),
        });

        await updatePolicy(
          ignitionToken,
          policyStubbed.id,
          accountStubbed.id,
          documentsObject
        );

        accountId = accountStubbed.id;
        policyId = policyStubbed.id;
      }

      // Setting account and policy info if we have already surpassed retry count
      setAccountInfo({ id: accountId });
      setPolicyInfo({ id: policyId });
      setDocumentStatus("success");

      nextStep("success");
      return;
    } catch (error) {
      console.error(error);
      console.log("Failed with error... retry count is: ", manualRetryCount);
      setManualRetryCount(manualRetryCount + 1);
      switch (error.code) {
        case 400:
          nextStep("unsupported-document");
          return;
        case 500:
          if (error.message === "Text extraction failed") {
            setManualError("extract text from");
          } else if (
            error.message === "Failed to split and classify the document"
          ) {
            setManualError("accurately classify");
          } else if (error.message === "Failed to analyze the document") {
            setManualError("analyze text from");
          }
          nextStep("manual-document-error");
          return;
        default:
          setManualError("process");
          nextStep("manual-document-error");
          return;
      }
    }
  };

  const uploadFile = async () => {
    await trackPromise(uploadWrapper(), "carrier-loader");

    // Show nav buttons after CarrierLoader is unrendered
    setShowNav(true);
  };

  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  return (
    <>
      <ManualDropzone
        session={session}
        loginInformation={loginInformation}
        uploadFile={uploadFile}
        loadingSteps={loadingSteps}
        setFileData={setFileData}
        setFilePath={setFilePath}
        setHasFile={setHasFile}
        documentStatus={documentStatus}
      />
    </>
  );
};

export default EnhancedManualPolicyDocument;
