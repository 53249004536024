import { get, size } from "lodash";

export const genericClientLogoUrl =
  "https://axle-labs-assets.s3.amazonaws.com/clientLogos/generic.svg";

export const basePermissions = [
  {
    order: 1,
    name: "Policy Type",
    icon: "type",
    value: "type",
  },
  {
    order: 2,
    name: "Policy Status",
    icon: "checkmark",
    value: "isActive",
  },
  {
    order: 3,
    name: "Policy Number",
    icon: "policyNo",
    value: "policyNumber",
  },
  {
    order: 4,
    name: "Effective Date",
    icon: "calendar",
    value: "effectiveDate",
  },
  {
    order: 5,
    name: "Expiration Date",
    icon: "calendar",
    value: "expirationDate",
  },
  {
    order: 6,
    name: "Insureds",
    icon: "profile",
    value: "insureds",
  },
  {
    order: 8,
    name: "Coverages",
    icon: "shield",
    value: "coverages",
  },
  {
    order: 9,
    name: "Documents",
    icon: "document",
    value: "documents",
  },
];

export const getDocumentDisplayText = (session) => {
  let defaultDisplayText = [
    "Identification card",
    "Declarations page or policy renewal",
  ].map((element) => (
    <>
      <div className="text-sm">{element}</div>
    </>
  ));
  const acceptedDocs = get(session, "config.documentation.acceptedDocs");
  // If client has not specified accepted docs, return default display text
  if (!acceptedDocs) {
    return defaultDisplayText;
  }

  const displayNames = acceptedDocs.map((acceptedDoc) => {
    const acceptedDocDisplayName = get(acceptedDoc, "displayName", undefined);
    return acceptedDocDisplayName;
  });

  // Filter out any undefined values
  const filteredDisplayNames = displayNames.filter((element) => element);
  if (!size(filteredDisplayNames)) {
    return defaultDisplayText;
  }

  return filteredDisplayNames.map((element) => (
    <>
      <div className="text-sm">{element}</div>
    </>
  ));
};
